import React, { useEffect, useState } from "react";
import WisdomLogo from "../../image/logo/appLogoBlack.png";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import AdminLoader from "../../component/Loader";
import MWTextField from "../../component/MWTextField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { clearLoginAPIRes, jwtTokenApiCall } from "../../redux/jwtLogIn/API";
import { JwtTokenAuthBody } from "../../redux/jwtLogIn/Model";
import {
  adminLoginDetailsApi,
  clearGetAdminDetailsAPIRes,
} from "../../redux/jwtLogIn/loginDetails/API";
import { LoginBody } from "../../redux/jwtLogIn/loginDetails/Model";
import MWSnackbar from "../../component/MWSnackbar";

export default function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const apiAuthTokenValue = useSelector((state: AppState) => state.apiAuthToken);
  const adminLoginDetailsResponse = useSelector((state: AppState) => state.adminLoginDetailsRes);
  const [apiMsgType, setAPIMsgType] = useState<AlertColor>("success");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = useState("");
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPassword] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [errorpassword, setErrorPassword] = useState("");

  const authApiBody = {
    username: email.toLowerCase(),
    password: password.password,
  } as JwtTokenAuthBody;

  // initiate Login API
  const handleLogin = () => {
    if (email === "" && password.password === "") {
      setErrorEmail("Please enter email id");
      setErrorPassword("Please enter password");
    } else if (email === "" && password.password !== "") {
      setErrorEmail("Please enter email id");
      setErrorPassword("");
    } else if (email !== "" && password.password === "") {
      setErrorEmail("");
      setErrorPassword("Please enter password");
    } else {
      setError("");
      setErrorEmail("");
      setErrorPassword("");
      dispatch(clearLoginAPIRes());
      dispatch(clearGetAdminDetailsAPIRes());
      setError("");
      dispatch(jwtTokenApiCall(authApiBody));
    }
  };
  const [access, setToken] = useState("");
  useEffect(() => {
    if (apiAuthTokenValue?.loginRes?.access !== undefined) {
      setToken(apiAuthTokenValue?.loginRes?.access);
    }

    if (apiAuthTokenValue?.errorMsg !== "") {
      setAPIMsgType("error");
      setError(`${t("common.apitokenerror", { ns: ["home"] })}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAuthTokenValue]);

  useEffect(() => {
    if (access !== "") {
      dispatch(
        adminLoginDetailsApi({
          access: access,
        } as LoginBody),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  useEffect(() => {
    setIsLoading(adminLoginDetailsResponse?.loading);
    if (adminLoginDetailsResponse?.getAdminLoginDetailsRes?.user?.id !== undefined) {
      window.location.reload();
      setAPIMsgType("success");
      setSuccess(`${t("common.loginsuccess", { ns: ["home"] })}`);
    }
    if (adminLoginDetailsResponse?.errorMsg !== "") {
      setAPIMsgType("error");
      setError(adminLoginDetailsResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminLoginDetailsResponse]);
  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearGetAdminDetailsAPIRes());
    }
  };

  const actualPageMarkup = (
    <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
      <Grid item>
        <>
          {loadingMarkup}
          <Card sx={{ minWidth: 420 }}>
            <CardContent>
              <Box sx={{ m: 2 }} onKeyDown={keyDownEvent}>
                <img src={WisdomLogo} alt="Logo" height="80" />
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Log in to the dashboard
                  </Typography>
                </Box>
                <MWTextField
                  fullWidth
                  sx={{ my: 2 }}
                  size="medium"
                  value={email}
                  error={errorEmail === "" ? false : true}
                  label="Email"
                  placeholder="Enter your email"
                  id="emailFieldID"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                    setErrorEmail("");
                  }}
                  helperText={
                    errorEmail === "" ? (
                      <Box sx={{ color: "#000000" }}>Please provide an email</Box>
                    ) : (
                      errorEmail
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                />
                <MWTextField
                  id="outlined-adornment-password"
                  size="medium"
                  fullWidth
                  error={errorpassword === "" ? false : true}
                  placeholder="Enter password"
                  label="Password"
                  type={password.showPassword ? "text" : "Password"}
                  value={password.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="medium"
                        >
                          {password.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    errorpassword === "" ? (
                      <Box sx={{ color: "#000000" }}>Please provide password</Box>
                    ) : (
                      errorpassword
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                />
                <Box>
                  <Button
                    sx={{
                      my: 2,
                      backgroundColor: "#db330c",
                      "&:hover": {
                        backgroundColor: "#f49537",
                      },
                    }}
                    fullWidth
                    variant="contained"
                    onClick={handleLogin}
                    size="large"
                  >
                    Login
                  </Button>
                </Box>
                {error !== "" ? (
                  <Box>
                    <Alert
                      severity={apiMsgType}
                      onClose={() => {
                        dispatch(clearLoginAPIRes());
                        dispatch(clearGetAdminDetailsAPIRes());
                        setError("");
                      }}
                    >
                      {error}
                    </Alert>
                  </Box>
                ) : null}
                <MWSnackbar msg={success} type={apiMsgType} alertClose={alertCloseAction} />
              </Box>
            </CardContent>
          </Card>
        </>
      </Grid>
    </Grid>
  );
  return <Container maxWidth="xl">{actualPageMarkup}</Container>;
}
